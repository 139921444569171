



/* Single image */
import Timg1 from '../images/team/img-1.jpg'
import Timg2 from '../images/team/img-2.jpg'
import Timg3 from '../images/team/img-3.jpg'
import Timg4 from '../images/team/img-4.jpg'





const Services = [
    {
        id: 1,
        simage: Timg1,
        title: 'Harry Abraham',
        Subtitle: 'Agriculture Farmer',
    },
 
    {
        id: 2,
        simage: Timg2,
        title: 'Alexa Mitra',
        Subtitle: 'Vegetables Farmer',
    },
 
    {
        id: 3,
        simage: Timg3,
        title: 'Robert Johnson',
        Subtitle: 'Agriculture Farmer',
    },
 
    {
        id: 4,
        simage: Timg4,
        title: 'Jenelia Orkid<',
        Subtitle: 'Vegetables Farmer',
    },
 



];
export default Services;