
/* Single image */
import img1 from '../images/service/img-1.jpg'
import img2 from '../images/service/img-2.jpg'
import img3 from '../images/service/img-3.jpg'
import img4 from '../images/service/img-4.jpg'



import sion1 from '../images/service/icon-1.svg'
import sion2 from '../images/service/icon-2.svg'
import sion3 from '../images/service/icon-3.svg'
import sion4 from '../images/service/icon-4.svg'


import Simg1 from '../images/service-single/img1.jpg'
import Simg2 from '../images/service-single/img2.jpg'
import Simg3 from '../images/service-single/img3.jpg'
import Simg4 from '../images/service-single/img3.jpg'

const Services = [
    {
        id: 1,
        icon: sion1,
        simage: img1,
        imag: Simg1,
        title: 'Agriculture Products',
        description: 'We have more doctor for your dental illness. We are here for your better treatment',
        slug: 'Agriculture-Products'
    },
    {
        id: 2,
        icon: sion2,
        simage: img2,
        imag: Simg2,
        title: 'Dairy Products',
        description: 'We have more doctor for your dental illness. We are here for your better treatment',
        slug: 'Dairy-Products'
    },
    {
        id: 3,
        icon: sion3,
        simage: img3,
        imag: Simg3,
        title: 'Organic Fruits',
        description: 'We have more doctor for your dental illness. We are here for your better treatment',
        slug: 'Organic-Fruits'
    },
    {
        id: 4,
        icon: sion4,
        simage: img4,
        imag: Simg4,
        title: 'Fresh Vegetables',
        description: 'We have more doctor for your dental illness. We are here for your better treatment',
        slug: 'Fresh-Vegetables'
    }
   


];
export default Services;